import React, { useEffect } from "react";
import "./podcast.css";
import ReactPlayer from "react-player";
// import { client } from "../../Components/Client/client";
// import { useCallback, useState } from "react";
// import { ScrollPage } from "react-scroll-motion";
// import HeaderAnimation from "../../Components/HeaderAnimation/headerAnimation";

export default function Podcast() {
    const myStyle = {
        maxWidth: "500px",
        boxShadow: "2px 2px 2px 1px black;",
        borderRadius: "6px",
        padding: "10px",
    };
    // const style = {
    //     height: 600,
    // };
    return (
        <div className="pageSection" id="podcast">
            <div data-aos="zoom-in-up" className="sectionHeading">
                <h1 className="sectionHeading">PODCAST</h1>
            </div>
            <div className="podcastSectionWrapper">
                <div data-aos="fade-right">
                    <dispatchEvent>
                        <ReactPlayer
                            light={false}
                            width={"95vw"}
                            height={"400px"}
                            style={myStyle}
                            className={"soundCloudPlayer"}
                            url={
                                "https://soundcloud.com/soundsoundcologne/sets/podcasts"
                            }
                        />
                    </dispatchEvent>
                </div>
                <div className="podcastDescription">
                    <p>
                        With our SOUNDSOUND Podcast series, we are continuously
                        looking for talents contributing to our collection of
                        crispy sounds. Next to our in-house artists, we have a
                        broad mix of different DJ's delivering exclusive mixes
                        just for you.
                    </p>
                </div>
            </div>
        </div>
    );
}
