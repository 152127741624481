import React, { useEffect } from "react";
import { client } from "../../Components/Client/client";
import { useCallback, useState } from "react";
import "./releases.css";
import { Spotify } from "react-spotify-embed";
import Slider from "react-slick";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export default function Releases() {
    const [release, setRelease] = useState();

    const options = {
        renderMark: {
            [MARKS.BOLD]: (text) => <b>{text}</b>,
            [MARKS.ITALIC]: (text) => <em>{text}</em>,
            [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
            [MARKS.CODE]: (text) => <code>{text}</code>,
        },

        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
            // Für Überschriften
            [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
            [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
            [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
            [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
            [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
            [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
            // Für Codeblöcke
            [BLOCKS.CODE]: (node, children) => (
                <pre>
                    <code>{children}</code>
                </pre>
            ),
            // Fügen Sie hier weitere Blocktypen hinzu, die Sie behandeln möchten
        },
    };

    const getReleases = useCallback(async () => {
        try {
            const response = await client.getEntries({
                content_type: "release",
            });
            console.log("response ✅", response);

            const responseData = response.items;
            const releasesArray = responseData.map((release) => {
                const { fields, sys } = release;
                const { id } = sys;
                const {
                    releaseName,
                    artistName,
                    albumCover,
                    releaseDate,
                    albumDescription,
                    spotifyLink,
                    bandcampLink,
                    beatportLink,
                    catalogueNumberg,
                } = fields;
                const { url } = albumCover.fields.file;
                // const { value } = albumDescription.content[0].content[0];
                const obj = {
                    releaseName,
                    artistName,
                    albumCover,
                    releaseDate,
                    albumDescription,
                    spotifyLink,
                    bandcampLink,
                    beatportLink,
                    catalogueNumberg,
                    id,
                    url,
                    // value,
                };
                console.log("RELEASE OBJ", obj);

                return obj;
            });
            setRelease(releasesArray);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getReleases();
    }, [getReleases]);
    const sliderStyle = {
        margin: 10,
        maxWidth: "100%",
    };
    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: false,
        autoplaySpeed: 5000,
        adaptiveHeight: true,
        style: sliderStyle,
    };
    return (
        <div className="pageSection" id="releases">
            <div data-aos="fade-down" className="sectionHeading">
                <h1 className="sectionHeading">RELEASES</h1>
            </div>

            <div className="releasesSlider">
                <Slider {...settings}>
                    {release &&
                        release.map((release) => {
                            return (
                                <div className="releasesContent">
                                    <img
                                        className="albumCover"
                                        key={release.id}
                                        src={release.url}
                                        alt={release.releaseName}
                                    ></img>
                                    <div className="albumDescription">
                                        <h2>{release.artistName}</h2>
                                        {documentToReactComponents(
                                            release.albumDescription,
                                            options
                                        )}
                                        {/* <p>{release.value}</p> */}
                                    </div>
                                    {/* <Spotify link={release.spotifyLink} /> */}

                                    <iframe
                                        // style="border-radius:12px"
                                        src={release.spotifyLink}
                                        width="100%"
                                        height="200"
                                        frameBorder="0"
                                        allowfullscreen=""
                                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                        loading="lazy"
                                        title="SpotifyPlayer"
                                    ></iframe>
                                </div>
                            );
                        })}
                </Slider>
            </div>
        </div>
    );
}
