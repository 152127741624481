import React, { useEffect } from "react";
import { client } from "../../Components/Client/client";
import { useCallback, useState } from "react";

// import HeaderAnimation from "../../Components/HeaderAnimation/headerAnimation";

export default function LogoBlack() {
    const [logo, setLogo] = useState({});
    const [isRotated, setIsRotated] = useState(false);
    const [rotation, setRotation] = useState(0);

    const getLogos = useCallback(async () => {
        try {
            const response = await client.getEntries({
                content_type: "logo",
            });
            const responseData = response.items;
            // console.log("LOGO", responseData);

            const logoBlackUrl =
                responseData[0].fields.logoBlack.fields.file.url;
            // console.log("LOGO URL", logoBlackUrl);
            // const logoArray = responseData.map((logo) => {
            //     console.log("LOGO URL", url);
            //     return { url };
            // });
            setLogo(logoBlackUrl);
            return logoBlackUrl;
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getLogos();
    }, [getLogos]);

    const rotateImage = () => {
        console.log("click on Image");
        setRotation(rotation + 180);
    };
    return (
        <>
            <img
                onClick={rotateImage}
                id="navLogo"
                src={logo}
                alt=""
                noloading
                style={{
                    transform: `rotate(${rotation}deg)`,
                    transition: "transform 0.5s ease",
                }}
            ></img>
        </>
    );
}
