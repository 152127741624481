import React, { useEffect, useCallback, useState } from "react";
import { client } from "../../Components/Client/client";
import "./hero.css";
// import BIRDS from "vanta/dist/vanta.birds.min";
// import HALO from "vanta/dist/vanta.halo.min";

// import {
//     Animator,
//     ScrollPage,
//     batch,
//     Fade,
//     StickyIn,
// } from "react-scroll-motion";
import LogoWhite from "../Logo/logoWhite";

export default function Hero() {
    const [hero, setHero] = useState();

    // const [vantaEffect, setVantaEffect] = useState(0);
    // const myRef = useRef(null);

    // const Spin = (cycle) => ({
    //     in: {
    //         style: {
    //             // `p` is number (0~1)
    //             // When just before this page appear, `p` will be 0
    //             // When this page filled your screen, `p` will be 1
    //             transform: (p) => `rotate(${p * 360 * cycle}deg)`,
    //         },
    //     },
    //     out: {
    //         style: {
    //             // `p` is number (0~1)
    //             // When this page filled your screen, `p` will be 0
    //             // When just after this page disappear, `p` will be 1
    //             transform: (p) => `rotate(${p * 360 * cycle}deg)`,
    //         },
    //     },
    // });
    const getHero = useCallback(async () => {
        try {
            const response = await client.getEntries({
                content_type: "hero",
            });
            console.log("HERO response", response);
            const responseData = response.items;
            // const heroUrl = hero.fields.heroImages.fields.file.url;
            const heroArray = responseData.map((hero) => {
                const { url } = response.items[0].fields.heroImage.fields.file;
                console.log("HERO URL", url);
                console.log("HERO           ", hero);
                // console.log("ID", hero.sys.id);

                return { url };
            });

            setHero(heroArray);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getHero();
    }, [getHero]);

    // useEffect(() => {
    //     if (!vantaEffect) {
    //         setVantaEffect(
    //             HALO({
    //                 el: myRef.current,
    //                 mouseControls: true,
    //                 touchControls: true,
    //                 gyroControls: false,
    //                 minHeight: 1200.0,
    //                 minWidth: 200.0,
    //                 yOffset: 0.2,

    //                 // backgroundColor: 0x282839,
    //                 backgroundColor: 0x131a43,

    //                 // yOffset: -0.1,

    //                 // size: 1.5,
    //             })
    //         );
    //     }
    //     return () => {
    //         if (vantaEffect) vantaEffect.destroy();
    //     };
    // }, [vantaEffect]);

    return (
        <div className="pageSection" id="hero">
            {/* {/* <div className="vanta" ref={myRef}> */}

            <div id="heroImageWrapper">
                <LogoWhite />
                {hero &&
                    hero.map((heroImage) => {
                        return (
                            <div key={heroImage.url}>
                                <img
                                    className="heroImage"
                                    src={heroImage.url}
                                    alt=""
                                ></img>
                            </div>
                        );
                    })}
                {/* <ScrollPage page={0}>
                    <>
                        <Animator
                            id="animator"
                            animation={batch(StickyIn(), Fade(), Spin(3))}
                        >
                            <div className="heroContainer">
                                <div>
                                    <LogoWhite></LogoWhite>
                                </div>
                            </div>
                        </Animator>
                    </>
                </ScrollPage> */}
            </div>
        </div>
    );
}
