import React, { useEffect } from "react";
import { client } from "../../Components/Client/client";
import { useCallback, useState } from "react";
import "./merch.css";
import "./merchSlider.css";
// import "../Events/slider.css";
import Slider from "react-slick";
import HeaderAnimation from "../../Components/HeaderAnimation/headerAnimation.js";
import { ScrollPage } from "react-scroll-motion";
import {
    Animator,
    // ScrollContainer,
    // ScrollPage,
    batch,
    Fade,
    FadeOut,
    // FadeIn,
    // FadeOut,
    // Move,
    // MoveIn,
    // MoveOut,
    // Sticky,
    // StickyIn,
    // StickyOut,
    Zoom,
    // ZoomIn,
    // ZoomOut,
} from "react-scroll-motion";
//
//
//
//

export default function Merch() {
    const [merch, setMerch] = useState();
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    // const [slider, setSlider] = useState(true);

    const getMerch = useCallback(async () => {
        try {
            const response = await client.getEntries({
                content_type: "merch",
            });
            // console.log("response", response);
            const responseData = response.items;
            // console.log("MERCH", responseData.fields.merchInfo);
            // console.log("Responsedata MERCH", responseData);
            const merchArray = responseData.map((merch) => {
                const { fields, sys } = merch;
                const { id } = sys;
                const { merchInfo, merchImage, price, shopLink, articleName } =
                    fields;
                // console.log("MERCH INFO", merchInfo);
                const { url } = fields.merchImage[0].fields.file;

                const obj = {
                    merchInfo,
                    url,
                    id,
                    price,
                    shopLink,
                    articleName,
                };
                // console.log("MERCH OBJ", obj);
                return obj;
            });

            setMerch(merchArray);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getMerch();
    }, [getMerch]);

    // const toggleSlider = () => setSlider((value) => !value);
    // const closeSlider = () => {
    //     setSlider(false);
    //     console.log("closing Slider");
    // };
    const sliderStyle = {
        // objectFit: "fill",
        // maxWidth: "100%",
        // height: "400px",
    };
    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: false,
        autoplaySpeed: 5000,
        adaptiveHeight: true,
        style: sliderStyle,
    };
    const settings2 = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: false,
        autoplaySpeed: 3000,
        adaptiveHeight: true,
        style: sliderStyle,
        className: "smallSliderContent",
    };

    return (
        <>
            <div className="pageSection" id="merch">
                <div data-aos="fade-down" className="sectionHeading">
                    <h1 className="sectionHeading"> SHOP </h1>
                </div>
                <div className="sliderContainer">
                    <div className="merchSlider">
                        <Slider
                            {...settings}
                            asNavFor={nav2}
                            ref={(slider1) => setNav1(slider1)}
                        >
                            {merch &&
                                merch.map((article) => {
                                    return (
                                        <>
                                            <div className="sliderContent">
                                                <img
                                                    className="merchSliderImage"
                                                    key={article.id}
                                                    src={article.url}
                                                    alt={article.articleName}
                                                ></img>
                                            </div>
                                            <div className="merchInfo">
                                                <div className="merchSliderDescription">
                                                    <h3>
                                                        {article.articleName}
                                                        <br></br>
                                                        {article.merchInfo}
                                                        <br></br>
                                                        {/* <br></br> */}
                                                        {/* {article.price} */}
                                                    </h3>
                                                </div>
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={article.shopLink}
                                                >
                                                    <button className="merchButton">
                                                        BUY
                                                    </button>
                                                </a>
                                            </div>
                                        </>
                                    );
                                })}
                        </Slider>
                        {/* <div className="smallSlider">
                            <Slider
                                {...settings2}
                                asNavFor={nav1}
                                ref={(slider2) => setNav2(slider2)}
                                slidesToShow={3}
                                swipeToSlide={true}
                                focusOnSelect={true}
                            >
                                {merch &&
                                    merch.map((article) => {
                                        return (
                                            <>
                                                <div className="smallSliderContent">
                                                    <img
                                                        key={article.id}
                                                        src={article.url}
                                                        alt={
                                                            article.articleName
                                                        }
                                                    ></img>
                                                </div>
                                            </>
                                        );
                                    })}
                            </Slider>
                        </div> */}
                    </div>
                    <div data-aos="zoom-in" className="shop">
                        <a
                            href="https://soundsound.bandcamp.com/album/soso001-shine-on-you-ep"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <button id="shopButton">SHOP</button>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}
