import React from "react";

export default function Contact() {
    return (
        <div className="pageSection" id="contact">
            <div data-aos="fade-left" className="sectionHeading">
                <h1 className="sectionHeading"> CONTACT </h1>
            </div>
        </div>
    );
}
