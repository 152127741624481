import React, { useEffect } from "react";
import { client } from "../../Components/Client/client";
import { useCallback, useState } from "react";

// import HeaderAnimation from "../../Components/HeaderAnimation/headerAnimation";

export default function LogoWhite() {
    const [logo, setLogo] = useState({});
    const [rotation, setRotation] = useState(0);

    const getLogos = useCallback(async () => {
        try {
            const response = await client.getEntries({
                content_type: "logo",
            });
            const responseData = response.items;
            // console.log("LOGO", responseData);

            const logoWhiteUrl =
                responseData[0].fields.logoWhite.fields.file.url;
            // console.log("LOGO URL", logoWhiteUrl);
            // const logoArray = responseData.map((logo) => {
            //     console.log("LOGO URL", url);
            //     return { url };
            // });
            setLogo(logoWhiteUrl);
            return logoWhiteUrl;
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getLogos();
    }, [getLogos]);

    const rotateImage = () => {
        console.log("click on Image");
        setRotation(rotation + 180);
    };
    return (
        <>
            <img
                onClick={rotateImage}
                id="logoHero"
                // className={isRotated ? "rotate-img" : ""}
                style={{
                    transform: `rotate(${rotation}deg)`,
                    transition: "transform 0.5s ease",
                }}
                src={logo}
                alt=""
                noloading
            ></img>
        </>
    );
}
