import React, { useEffect } from "react";
import Booking from "../../pages/Booking/artists";
import Contact from "../../pages/Contact/contact";
import Events from "../../pages/Events/events";
import Merch from "../../pages/Merch/merch";
import Podcast from "../../pages/Podcast/podcast";
import "./home.css";
// import { useState, useEffect } from "react";
import Navbar from "../Navbar/navbar";
import Footer from "../Footer/footer";
import About from "../../pages/About/about";
import Hero from "../Hero /hero";

// AOS
import Aos from "aos";
import "aos/dist/aos.css";
//

import { ScrollContainer } from "react-scroll-motion";
import News from "../../pages/News/news";
import HeaderAnimation from "../HeaderAnimation/headerAnimation";
import Artists from "../../pages/Booking/artists";
import Releases from "../../pages/Releases/releases";
var contentful = require("contentful");

export default function Home() {
    useEffect(() => {
        Aos.init({ duaration: 2000 });
    }, []);
    return (
        <ScrollContainer>
            <>
                <div className="sectionWrapper">
                    {/* <Navbar /> */}
                    <Hero />
                    <Events />
                    <div
                        data-aos="zoom-in"
                        data-aos-duration="1500"
                        className="trennerContainer"
                    >
                        <div className="trennerLinie"></div>
                    </div>
                    <Merch />
                    <div
                        data-aos="zoom-in"
                        data-aos-duration="1500"
                        className="trennerContainer"
                    >
                        <div className="trennerLinie"></div>
                    </div>
                    {/* <Releases /> */}
                    {/* <div
                        data-aos="zoom-in"
                        data-aos-duration="1500"
                        className="trennerContainer"
                    >
                        <div className="trennerLinie"></div>
                    </div> */}
                    <Artists></Artists>
                    <div
                        data-aos="zoom-in"
                        data-aos-duration="1500"
                        className="trennerContainer"
                    >
                        <div className="trennerLinie"></div>
                    </div>
                    <Podcast />
                    {/* <div
                        data-aos="zoom-in"
                        data-aos-duration="1500"
                        className="trennerContainer"
                    >
                        <div className="trennerLinie"></div>
                    </div> */}
                    {/* <About /> */}

                    {/* <Contact /> */}
                    {/* <Footer /> */}
                </div>
            </>
        </ScrollContainer>
    );
}
