import "./App.css";
import React from "react";

// import Aos from "aos";
import "aos/dist/aos.css";

import Home from "./Components/Home/home";
import Contact from "./pages/Contact/contact";
import Imprint from "./pages/Impressum/imprint";
import Releases from "./pages/Releases/releases";
// import { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar/navbar";
import Footer from "./Components/Footer/footer";
function App() {
    return (
        <BrowserRouter>
            <Navbar></Navbar>
            <Routes>
                <Route exact path="/" element={<Home />} />
                {/* <Route exact path="/events" element={<Events />} /> */}
                <Route exact path="/releases" element={<Releases />} />

                <Route exact path="/contact" element={<Contact />} />
                <Route exact path="/imprint" element={<Imprint />} />
            </Routes>
            <Footer></Footer>
        </BrowserRouter>
    );
}

export default App;
