import React, { useEffect } from "react";
import { client } from "../../Components/Client/client";
import { useCallback, useState } from "react";
import Navbar from "../../Components/Navbar/navbar";
import Slider from "react-slick";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ReactMarkdown from "react-markdown";

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import "./eventsSlider.css";
import "./events.css";
import HeaderAnimation from "../../Components/HeaderAnimation/headerAnimation";

// AOS
import Aos from "aos";
import "aos/dist/aos.css";

export default function Events() {
    const [events, setEvents] = useState();
    const [isHovering, setIsHovering] = useState(false);

    const getEvents = useCallback(async () => {
        try {
            const response = await client.getEntries({
                content_type: "events",
            });
            // console.log("response🟢", response);
            const responseData = response.items;
            // console.log("Responsedata🟢", responseData);
            const eventsArray = responseData.map((event) => {
                const { fields, sys } = event;
                const { id } = sys;
                const {
                    eventName,
                    eventDate,
                    eventFlyer,
                    eventInfo,
                    ticketLink,
                } = fields;
                const { url } = eventFlyer.fields.file;
                const obj = {
                    eventDate,
                    url,
                    eventInfo,
                    id,
                    ticketLink,
                    eventName,
                };
                console.log("🛑", event.eventInfo);

                console.log("EVENT OBJ", obj);
                return obj;
            });

            setEvents(eventsArray);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        // Aos.init({ duration: 500 });
        getEvents();
    }, [getEvents]);

    const handleMouseOver = () => {
        setIsHovering(true);
        // console.log("hovering");
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };
    const sliderStyle = {
        margin: 10,
        maxWidth: "100%",
    };
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: false,
        autoplaySpeed: 5000,
        adaptiveHeight: true,
        variableWidth: false,
        style: sliderStyle,
    };

    return (
        <div className="pageSection" id="events">
            <div data-aos="zoom-in" className="sectionHeading">
                <h1 className="sectionHeading" id="eventHeading">
                    EVENTS
                </h1>
            </div>
            {/* <ScrollPage page={1}>
                <HeaderAnimation header={"EVENTS"}></HeaderAnimation>
            </ScrollPage> */}

            <div className="eventSlider">
                <Slider {...settings}>
                    {events &&
                        events.map((event) => {
                            return (
                                <div className="eventWrapper">
                                    <div className="hoverDiv">
                                        <img
                                            // layout="responsive"
                                            // width="600"
                                            // height="460"
                                            key={event.id}
                                            onMouseOver={handleMouseOver}
                                            onMouseOut={handleMouseOut}
                                            id="eventFlyer"
                                            src={event.url}
                                            alt="Event Flyer"
                                        ></img>
                                        <div className="eventInfo">
                                            <b>
                                                <h2>{event.eventName}</h2>
                                            </b>
                                            <br></br>
                                            {/* <p>{event.eventInfo}</p> */}
                                            <ReactMarkdown>
                                                {event.eventInfo}
                                            </ReactMarkdown>

                                            <br></br>
                                            <p>{event.eventDate}</p>
                                            {event.ticketLink && (
                                                <a
                                                    id="ticketLink"
                                                    target={"_blank"}
                                                    rel="noreferrer"
                                                    href={event.ticketLink}
                                                    style={{
                                                        textDecoration: "none",
                                                    }}
                                                >
                                                    <button className="ticketButton">
                                                        TICKETS
                                                    </button>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </Slider>
            </div>
        </div>
    );
}
